import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { pagesFetch, pagesEdit} from '../../store/AC/pages';
import { Root, Button, BtnContainer, Text, Error, List } from './styles';
import Item from './Item';

function Slider({pagesFetch, pagesEdit, pages}){
  const {page, status, error} = pages
  const [list, setList] = useState( page?.carusel || [])
  useEffect(() => {
    pagesFetch({name:'home'});
  }, [pagesFetch]);

  useEffect(() => {
    setList(page?.carusel || [])
  }, [page]);

  const addItem = () => {
    list.push({isHtml:true, data:{title:'', text:''}})
    setList( [...list])
  }

  const deleteItem = item => {
    let index = list.indexOf( item )
    if(index >= 0){
      list.splice(index, 1)
    }
    setList( [...list] )
  }

  const saveSlider = () => {
    pagesEdit({ name:'home', carusel:list })
  }

  const upItem = el => {
    let index = list.indexOf(el)
    if(index > 0){
      let item = list.splice(index, 1)[0]
      list.splice(index-1,0, item)
      setList( [...list] )
    }
  }
  
  const downItem = el => {
    let index = list.indexOf(el)
    if( index >= 0 ?? index !== (list.length - 1) ){
      let item = list.splice(index, 1)[0]
      list.splice(index+1,0, item)
      setList( [...list] )
    }
  }

  return( 
    <Root>
      {
        status === null &&
        <Text>Загрузка...</Text>
      }
      {
        status === 'error' &&
        <Error>Ошибка загрузки</Error>
      }
      {status === 'saccess' && <>
          <List>
            {list.map( (el, index) => 
              <Item key={el.data.title + ' ' + index} 
                data={el} 
                deleteItem={deleteItem}
                upItem={upItem}
                downItem={downItem}
              />
            )}
          </List>
          <BtnContainer>
            <Button onClick={saveSlider}>Сохранить</Button>
            <Button onClick={addItem}>Добавить</Button>
          </BtnContainer>
        </>
      }
      
    </Root>
  )
}

const mapStateToProps = ({ pages }) => ({
  pages,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  pagesFetch,
  pagesEdit,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Slider)