import React, { useState } from 'react';
import { Root } from './styles';


function LinkListInput({ data, keyName, disabled, placeholder}) {

  const [value, setValue] = useState(data[keyName]);

  const inputChage = ({value}) => {
    setValue(value)
    data[keyName] = value
  }
  return (
    <Root placeholder={placeholder} value={value} onChange={e => inputChage( e.target )} />
  );
}

export default LinkListInput