
import React, { useState } from 'react';
import { Root, Title, Header, Button, HeaderForm, Form, FormItem,
  LinkListInputContainer, LinkListTitle, FormItemHeader, BtnDeleteFormItem,
  BtnContainer,
} from './styles';
import Input from './Input'
import LinkListInput from './LinkListInput'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import SunEditor, {buttonList} from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File

function GraduateItem({ data, deleteItem, savePage, upItem, downItem }) {
  const [isOpen, setIsOpen] = useState(false)
  const [state, setState] = useState(data)
  const isPaper = (state.type === 'paper')
  const isLink = (state.type === 'link')

  const saveGraduate = a => {
    savePage()
  }
  const setRichText = ( text, item ) => {
    
    item.text = text
  }
  const deletePaper = (ind, type) =>{
    let res
    if(type === 'text'){
      res = window.confirm('Удалить блок с текстом?')
    }
    if(type === 'link'){
      res = window.confirm('Удалить блок с ссылками?')
    }
    if(res){
      state.paper.splice(ind,1)
      setState({...state})
    }
  }
  const upPaper = ind => {
    let paper = state.paper.splice(ind,1)[0]
    state.paper.splice(ind-1, 0 , paper)
    setState({...state})
  }
  const downPaper = ind => {
    let paper = state.paper.splice(ind,1)[0]
    state.paper.splice(ind+1, 0 , paper)
    setState({...state})
  }
  const addBlockText = () => {
    state.paper.push({
      typeText: true, 
      text:'',
    })
    setState({...state})
  }
  const addBlockLink = ()=>{
    state.paper.push({
      typeLinks: true,
      title: "", 
      linksList:[],
    })
    setState({...state})
  }
  const addLinks = (index)=>{
    state.paper[index].linksList.push({
      text:'',
      href:'',
    })
    setState({...state})
  }
  const removeLinks = (index)=>{
    state.paper[index].linksList.pop()
    setState({...state})
  }

  return (
    <Root>
      {!isOpen && 
      <Header>
        <Title>{state.title}</Title>
        <BtnContainer>
          <BtnDeleteFormItem onClick={()=> upItem(data)}>&and;</BtnDeleteFormItem>
          <BtnDeleteFormItem onClick={()=> downItem(data)}>&or;</BtnDeleteFormItem>
          <Button onClick={() => setIsOpen(true)}>Открыть</Button>
        </BtnContainer>
      </Header>}
      {isOpen && <Form>
        <HeaderForm> 
          <Button onClick={() => setIsOpen(false)} >Закрыть</Button>
        </HeaderForm>
        {isPaper && <>
          <FormItem>
            <Input data={data} keyName={'title'} title={"Заголовок"}/>
          </FormItem>
          <FormItem>
            <Input data={data} keyName={'href'} title={"Указатель (придумайте уникальный код. Пример:'key_name') "}/>
          </FormItem>
          {state.paper.map( (el, index) => 
            <FormItem key={'paper_index_' + index + el.text}>
              {el.typeText && <>
                <FormItemHeader>
                  <LinkListTitle>
                    Текстовый редактор
                  </LinkListTitle>
                  <BtnContainer>
                    {index !== 0 && 
                      <BtnDeleteFormItem onClick={()=> upPaper(index)}>&and;</BtnDeleteFormItem>
                    }
                    {index !== (state.paper.length - 1) && 
                      <BtnDeleteFormItem onClick={()=> downPaper(index)}>&or;</BtnDeleteFormItem>
                    }
                    
                    <BtnDeleteFormItem onClick={()=>deletePaper(index, 'text')}>&times;</BtnDeleteFormItem>
                  </BtnContainer>
                </FormItemHeader>
                <LinkListTitle>
                  
                </LinkListTitle>
                <SunEditor setOptions={{ 
                    height: 200,
                    buttonList: buttonList.complex
                  }}
                  lang="ru"
                  defaultValue={el.text}
                  onChange={text => setRichText(text, el) }
                />
              </>
                
              }
              {el.typeLinks && <div>
                <FormItemHeader>
                  <LinkListTitle>
                    Список ссылок
                  </LinkListTitle>
                  <BtnContainer>
                    {index !== 0 && 
                      <BtnDeleteFormItem onClick={()=> upPaper(index)}>&and;</BtnDeleteFormItem>
                    }
                    {index !== (state.paper.length - 1) && 
                      <BtnDeleteFormItem onClick={()=> downPaper(index)}>&or;</BtnDeleteFormItem>
                    }
                    <BtnDeleteFormItem onClick={()=>deletePaper(index, 'link')}>&times;</BtnDeleteFormItem>
                  </BtnContainer>
                </FormItemHeader>
                {el.linksList.map((link,ind) =>(<LinkListInputContainer key={'link_key_'+link.href +link.text + ind}>
                  <LinkListInput placeholder="Текст ссылки"  data={link} keyName='text' />
                  <LinkListInput placeholder="Аддрес ссылки" data={link} keyName='href' />
                </LinkListInputContainer>))}
                <BtnContainer>
                  <Button onClick={()=> addLinks(index)}>Добавить ссылку</Button>
                  <Button onClick={()=> removeLinks(index)}>Удалить ссылку</Button>
                </BtnContainer>
              </div>


              }
            </FormItem>
          )}
          <BtnContainer>
            <Button onClick={deleteItem}>Удалить раздел</Button>
            <Button onClick={addBlockText}>Добавить текстовый блок</Button>
            <Button onClick={addBlockLink}>Добавить блок ссылок</Button>
            <Button onClick={saveGraduate}>Сохранить</Button>
          </BtnContainer>
        </>
        }
        {isLink && <>
          <FormItem>
            <Input data={data} keyName={'title'} title={"Заголовок"}/>
          </FormItem>
          <FormItem>
            <Input data={data} keyName={'href'} title={"Сcылка (Вставьте ссылку на файл)"}/>
          </FormItem>
          <BtnContainer>
            <Button onClick={deleteItem}>Удалить раздел</Button>
            <Button onClick={saveGraduate}>Сохранить</Button>
          </BtnContainer>
        </>
        }
        
      </Form>}
      
    </Root>
  );
}

const mapDispatchToProps = dispatch => bindActionCreators({

}, dispatch);

export default connect(null, mapDispatchToProps)(GraduateItem)