import React from "react";
import { Switch, Route } from 'react-router-dom';
import Reviews from '../Reviews';
import Users from '../Users';
import Teachers from '../Teachers';
import Courses from '../Courses';
import Graduate from '../Graduate';
import Folders from '../Folders';
import Slider from '../Slider';
import Structure from '../Structure';
import { NavBar } from '../../components';
import { Root, ContainerAdmin } from './styles';


function Admin(){
  

  return (
    <Root>
      <NavBar/>
      <ContainerAdmin>
        <Switch>
          <Route path="/courses" component={Courses} />
          <Route path="/users" component={Users} />
          <Route path="/teachers"  component={Teachers} />
          <Route path="/reviews"  component={Reviews} />
          <Route path="/graduate"  component={Graduate} />
          <Route path="/folders"  component={Folders} />
          <Route path="/slider"  component={Slider} />
          <Route path="/structure"  component={Structure} />
        </Switch>
      </ContainerAdmin>
    </Root>
  )
}

export default Admin