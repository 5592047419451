import React, { useEffect } from 'react';
import { Root, Text, Input, Button } from './styles';
import { bindActionCreators } from 'redux';
import { authFetch } from '../../store/AC/auth';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

function Loading({authFetch}) {

  useEffect(() => {
    authFetch();
  }, [authFetch]);

  let password = ''
  const setPassword = e => {
    password = e.target.value
  }
  const login = () => {
    authFetch(password)
  }

  return (
    <Root>
      <Text>Введите пароль</Text>
      <Input onChange={setPassword}/>
      <Button onClick={login}>Войти</Button>
    </Root>
  );
}

Loading.propTypes = {
  authFetch:  PropTypes.func.isRequired,
};
const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => bindActionCreators({
  authFetch,
}, dispatch);


export default connect(mapStateToProps,mapDispatchToProps)(Loading);