import { call, put } from 'redux-saga/effects';
import { folderSuccess, folderFail } from '../../AC/folders';
import FoldersApi from '../../../api/Folders';

function* deleteFile( {data} ){
  const {changes, fileName} = data
  console.log(changes, fileName, 'delete Saga');
  try {
    const response = yield call(FoldersApi.deleteFile, {path: changes.path, name:fileName});

    if (response.status === 200) {
      
      changes.files = changes.files.filter( el => el.name !== fileName )
      
      const response = yield call(FoldersApi.updata, changes);
      if (response.status === 200) {
        yield put(folderSuccess(changes));
      }
      if (response.status === 500) {
        console.log( response.data )
      }
    }

    if (response.status === 500) {
      console.log( response.data )
    }
  } catch(e) {
    console.error(e);
    yield put(folderFail(e.message));
  }
}

export default deleteFile;