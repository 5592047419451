import { call, put } from 'redux-saga/effects';
import { pagesEditSuccess, pagesEditFail } from '../../AC/pages';
import PageApi from '../../../api/Pages';

function* pageEdit({ data }){
  try {
    const response = yield call(PageApi.edit, data.name, data);
    
    if (response.status === 200) {
      yield put(pagesEditSuccess(response.data.value));
    }

    if (response.status === 500) {
      yield put(pagesEditFail(response.data));
    }
  } catch(e) {
    console.error(e);
    yield put(pagesEditFail(e.message));
  }
}

export default pageEdit;