import axios from 'axios';

class AuthApi {

  static check(password) {
    return axios
      .get('/api/auth', { params: { password }})
      .then(response => response)
      .catch(({ response }) => ({ ...response }));
  }
}

export default AuthApi;
