import { call, put } from 'redux-saga/effects';
import { teachersAddSuccess, teachersAddFail } from '../../AC/teachers';
import TeachersApi from '../../../api/Teachers';

function* create({ data }) {
  try {
    const response = yield call(TeachersApi.add, data);

    if (response.status === 200) {
      yield put(teachersAddSuccess(response.data));
    }

    if (response.status === 500) {
      yield put(teachersAddFail(data.id, response.data));
    }
  } catch(e) {
    console.error(e);
    yield put(teachersAddFail(data.id, e.message));
  }
}

export default create;