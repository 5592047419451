import * as actions from '../actions/teachers';

// GET ALL TEACHERS
export const teachersFetch = () => ({ type: actions.TEACHERS_FETCH });
export const teachersFetchSuccess = data => ({ type: actions.TEACHERS_FETCH_SUCCESS, data });
export const teachersFetchFail = error => ({ type: actions.TEACHERS_FETCH_FAIL, error });

// CREATE TEACHERS
export const teachersAdd = data => ({ type: actions.TEACHERS_ADD, data });
export const teachersAddSuccess = data => ({ type: actions.TEACHERS_ADD_SUCCESS, data });
export const teachersAddFail = error => ({ type: actions.TEACHERS_ADD_FAIL, error });
export const teachersAddStatus = status => ({ type: actions.TEACHERS_ADD_STATUS, status });

// EDIT TEACHERS
export const teachersEdit = data => ({ type: actions.TEACHERS_EDIT, data });
export const teachersEditSuccess = data => ({ type: actions.TEACHERS_EDIT_SUCCESS, data });
export const teachersEditFail = (id, error) => ({ type: actions.TEACHERS_EDIT_FAIL, id, error });

// REMOVE TEACHERS
export const teachersRemove = id => ({ type: actions.TEACHERS_REMOVE, id });
export const teachersRemoveSuccess = id => ({ type: actions.TEACHERS_REMOVE_SUCCESS, id });
export const teachersRemoveFail = (id, error) => ({ type: actions.TEACHERS_REMOVE_FAIL, id, error });
