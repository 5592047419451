import styled from 'styled-components';
import { 
  // FS_LGM, 
  BLACK_COLOR, 
  PRIMARY_COLOR,
  GRAY_COLOR,
  ACCENT_COLOR,
} from '../../constants/styles';

export const Root = styled.div`
  margin: 20px auto;
  max-width: 1000px;
`
export const Folder = styled.div`
  width: 120px;
  min-height: 120px;
  cursor: pointer;
  display:flex;
  flex-direction: column;
  align-items: center;
`
export const FolderIcon = styled.div`
  width: 100px;
  height: 100px;
  background-image: url('/images/folder_icon.png');
  background-size: cover;
  background-position: center;
  margin-bottom: 5px;
`
export const FolderText = styled.div`
  width: 120px;
  text-align: center;
`
export const FileList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 20px 0;
  margin: 20px 0;
  border-top: 1px solid ${GRAY_COLOR};
  border-bottom: 1px solid ${GRAY_COLOR};
`
export const BackBtn = styled.div`
  width:40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid ${PRIMARY_COLOR};
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: white;
  transition: all .3s;
  &:hover{
    color: white;
    background: ${PRIMARY_COLOR};
  }
`
export const Header = styled.div`
  display: flex;
  padding: 10px 0;
  margin-bottom: 20px;
  align-items: center;
`
export const Url = styled.div`
  display: flex;
  padding: 10px 20px;
  margin-left: 20px;
  background: white;
  border: 2px solid ${BLACK_COLOR};
  border-radius: 10px;
  flex: 1;
`
export const Footer = styled.div`
  display: flex;
  padding: 10px 0;
  align-items: flex-end;
`
export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 300px;
`
export const LabeInput = styled.label`
  display: flex;
  padding: 10px;
  background: white;
  border: 1px solid ${PRIMARY_COLOR};
  cursor: pointer;
  border-radius: 10px;
  font-weight: 700;
  font-size: 14px;
  transition: all .3s;
  margin-top: 5px;
  flex: 0;
  &:hover{
    background: ${PRIMARY_COLOR};
    color: white;
  }
`
export const InputFileText = styled.div`
  word-break:break-all;
`
export const InputFile = styled.input`
  position: absolute;
  left: -10000px;
`
export const UploadBtn = styled.label`
  display: flex;
  padding: 10px;
  background: white;
  border: 1px solid ${PRIMARY_COLOR};
  
  border-radius: 10px;
  font-weight: 700;
  font-size: 14px;
  transition: all .3s;
  opacity: .3;
  margin-left: 20px;
  &.active{
    opacity: 1;
    cursor: pointer;
    &:hover{
      background: ${PRIMARY_COLOR};
      color: white;
    }
  }
  
`
export const Image = styled.div`
  width: 120px;
  min-height: 120px;
  max-height: 210px;
  display:flex;
  flex-direction: column;
  align-items: center;
  display: flex;
  flex-direction: column;
  overflow:hidden;
  position: relative;
`
export const ImageIcon = styled.div`
  width: 100px;
  height: 100px;
  background-size: cover;
  background-position: center;
  flex-shrink: 0;
  margin-bottom: 5px;
`
export const ImageText = styled.div`
  width: 110px;
  text-align: center;
  word-break:break-all;
  text-overflow: ellipsis;
`
export const DeleteBtn = styled.div`
  position: absolute;
  top: 5px;
  right: 15px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  color: white;
  background: ${PRIMARY_COLOR};
  border:1px solid white;
  cursor: pointer;
`

export const GetUrlBtn = styled.div`
  position: absolute;
  top: 5px;
  left: 15px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  color: white;
  background: ${ACCENT_COLOR};
  border:1px solid white;
  cursor: pointer;
`



