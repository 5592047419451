import styled from 'styled-components';
import {
  // TABLET,
  // LAPTOP,
  // DESCTOP,
  // FS_MD,
  // FS_LGS,
  // FS_LG,
  // BLACK_COLOR,
  GRAY_COLOR
} from '../../constants/styles';

export const Root = styled.div`
  margin: 20px auto;
  max-width: 1000px;
  display: flex;
  justify-content: space-between;
`
export const Text = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  text-align: center;
  font-weight: 700;
`
export const Error = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  text-align: center;
  font-weight: 700;
  color: red;
`

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0;
  width: 100%;
`
export const Item = styled.li`
  display: flex;
  padding: 10px;
  border-bottom: 1px solid ${GRAY_COLOR};
`

export const Button = styled.button`
  padding: 12px 15px;
  font-family: 'Gotham';
  font-size: 14px;
  font-weight: bold;
  color: #252A34;
  background-color: #fff;
  border: 1px solid #FF165D;
  border-radius: 15px;
  cursor: pointer;
  transition: all .2s;

  &:hover{
    color: #fff;
    background-color: #FF165D;
  }
`
export const BtnContainer = styled.div`
  display: flex;
  gap: 10px;
`
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`


