import axios from 'axios';

class PagesApi {

  static getPage(name) {
    console.log('sec/api/Pages', name);
    return axios
      .get(`/api/page/${name}`)
      .then(response => response)
      .catch(({ response }) => ({ ...response }));
  }

  static edit(name, body) {
    return axios
      .put(`/api/page/${name}`, body)
      .then(response => response)
      .catch(({ response }) => ({ ...response }));
  }

  static upload(data) {
    console.log('api upload', data);
    return axios
      .post(`https://onpo.gpntbsib.ru/api/upload`, data)
      .then(response => response)
      .catch(({ response }) => ({ ...response }));
  }

  static updataFileData(data) {
    console.log('api upload', data);
    return axios
      .put(`/api/fileData/`, data)
      .then(response => response)
      .catch(({ response }) => ({ ...response }));
  }
}

export default PagesApi;
