import axios from 'axios';

class UsersApi {
  static fetchAll() {
    return axios
      .get('/api/teachers')
      .then(response => response)
      .catch(({ response }) => ({ ...response }));
  }
  static add(body) {
    return axios
      .post('/api/teachers', body)
      .then(response => response)
      .catch(({ response }) => ({ ...response }));
  }
  static edit(id, body) {
    return axios
      .put(`/api/teachers/${id}`, body)
      .then(response => response)
      .catch(({ response }) => ({ ...response }));
  }

  static remove(id) {
    return axios
      .delete(`/api/teachers/${id}`)
      .then(response => response)
      .catch(({ response }) => ({ ...response }));
  }
}

export default UsersApi;
