import React, { useState } from 'react';
import { Root, InputEdit, InputLabel } from './styles';


function GraduateInput({ data, keyName, title, disabled}) {

  const [value, setValue] = useState(data[keyName]);

  const inputChage = ({target}) => {
    setValue(target.value)
    data[keyName] = target.value
  }
  return (
    <Root>
      <InputLabel id='title'>{title}</InputLabel>
      <InputEdit name='title' disabled={disabled} value={value} onChange={e => inputChage( e )}/>
      
    </Root>
  );
}

export default GraduateInput