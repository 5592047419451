import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { teachersFetch } from '../../store/AC/teachers';
import { Root, Text, Error, ButtonsContainer, Button, Container } from './styles';
import { DataTablet, Modal } from '../../components';
import Edit from './Edit'

function Teachers({ teachersFetch, teachers }) {
  const { list, error } = teachers;
  
  const setting = {
    users:[ { key:"name", text:"ФИО", setting:{ width:17 }},
            { key:"course", text:"Название курса"}],
  }
  const content = !list ? null: list.map( el => (
    {...el, 
      show:{
        name: `${el.surname} ${el.name[0]}. ${el.middlename[0]}.`,
        course: el.course.reduce( (acc, el) => acc+el.title + ', ' , '')
      }
    }))

  useEffect(() => {
    teachersFetch();
  }, [teachersFetch]);

  const [isCreate, setIsCreate] = useState(false)

  const closeModal = _=>{
    document.body.style.overflowY = 'auto';
    setIsCreate(false)
  }

  return (
    <Root>
      {
        list === null && !error.list &&
        <Text>Загрузка...</Text>
      }

      {
        error.list &&
        <Error>Ошибка загрузки списка отзывов</Error>
      }

      {
        list && list.lingth === 0 &&
        <Text>Список преподователей пуст</Text>
      }

      {
        list && list.length && <>
          <Container>
            <ButtonsContainer>
              <Button onClick={ _=> setIsCreate(true) } >Добавить преподователя</Button>
            </ButtonsContainer>
          </Container>
          <DataTablet 
            setting={setting.users} 
            content={content}
          />
        </>
      }
      {
        isCreate && <Modal
          close={ closeModal}
        >
          <Edit closeModal={ closeModal } />
        </Modal>
      }
    </Root>
  );
}

Teachers.propTypes = {
  teachersFetch: PropTypes.func.isRequired,
  teachers:      PropTypes.object.isRequired,
};

const mapStateToProps = ({ teachers }) => ({
  teachers,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  teachersFetch,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Teachers);