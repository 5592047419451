import { call, put } from 'redux-saga/effects';
import { teachersEditSuccess, teachersEditFail } from '../../AC/teachers';
import TeachersApi from '../../../api/Teachers';

function* editFetch({ data }) {
  try {
    const response = yield call(TeachersApi.edit, data.id, data);

    if (response.status === 200) {
      yield put(teachersEditSuccess(response.data));
    }

    if (response.status === 500) {
      yield put(teachersEditFail(data.id, response.data));
    }
  } catch(e) {
    console.error(e);
    yield put(teachersEditFail(data.id, e.message));
  }
}

export default editFetch;