import React, { useState } from "react";
import { Root, TCol, Btn, Image, BtnContainer, EditContainer} from './styles';
import editSrc from '../../../images/edit.svg';
import removeSrc from '../../../images/remove.svg';
import TeacherEdit from "./../../../containers/Teachers/Edit"
import { Modal } from '../../../components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { teachersRemove } from '../../../store/AC/teachers';

function TRow( { index, content, THead, bthWidth, width, teachersRemove } ){

  const [ isOpen, setOpen ] = useState(false);
  const [ isOpenRemove, setOpenRemove ] = useState(false);

  const closeModal = _=>{
    document.body.style.overflowY = 'auto';
    setOpenRemove(false)
  }

  const remove = _=>{
    document.body.style.overflowY = 'auto';
    teachersRemove(content.id)
  }

  return (
    <Root>
      {!isOpen && <>
        <TCol>{index}</TCol>
        {THead.map( (el, ind) => { return (
          <TCol 
            key={'ind_key'+ind+content.id} 
            width={el.setting.width ? el.setting.width : width}
          >{ 
            content.show[el.key] 
          }</TCol>
        )})}
        <TCol width={bthWidth} >
          <Btn className={"yellow"} onClick={ _ => setOpen( !isOpen ) }>
            <Image src={editSrc} alt="" />
          </Btn>
        </TCol>
        <TCol width={bthWidth} >
          <Btn className={"red"} onClick={ _ => setOpenRemove(true) } >
            <Image src={removeSrc} alt="" />
          </Btn>  
        </TCol> 
      </>}
      { isOpen && 
        <TeacherEdit content={content} closeModal={ _=> { setOpen( false )  }}/>
      }
      {isOpenRemove && 
        <Modal
          title="Удаление преподователя" 
          close={() => setOpenRemove(false)}
        >
          <p>Вы действительно хотите удалить этого преподователя?</p>
          <p>{content.show.name}</p>
          <BtnContainer>
            <Btn className={"width_norm"} onClick={ remove }>
              Удалить
            </Btn>
            <Btn  className={"width_norm"} onClick={ closeModal }>
              Отмена
            </Btn>
          </BtnContainer>
        </Modal>
      }
    </Root>
  )
}

const mapStateToProps = dispatch => bindActionCreators({
  teachersRemove,
}, dispatch);

export default connect(null, mapStateToProps)(TRow)