import styled from 'styled-components';
import {
  PRIMARY_COLOR,
} from '../../../../constants/styles';

export const Root = styled.input`
  padding: 5px 15px;
  border: none;
  border-bottom: 1px solid ${PRIMARY_COLOR};
  outline: none;
  &:last-child{
    border-bottom: none;
  }
`