export const TEACHERS_FETCH = 'TEACHERS_FETCH';
export const TEACHERS_FETCH_SUCCESS = 'TEACHERS_FETCH_SUCCESS';
export const TEACHERS_FETCH_FAIL = 'TEACHERS_FETCH_FAIL';

export const TEACHERS_EDIT = 'TEACHERS_EDIT';
export const TEACHERS_EDIT_SUCCESS = 'TEACHERS_EDIT_SUCCESS';
export const TEACHERS_EDIT_FAIL = 'TEACHERS_EDIT_FAIL';

export const TEACHERS_ADD = 'TEACHERS_ADD';
export const TEACHERS_ADD_SUCCESS = 'TEACHERS_ADD_SUCCESS';
export const TEACHERS_ADD_FAIL = 'TEACHERS_ADD_FAIL';
export const TEACHERS_ADD_STATUS = 'TEACHERS_ADD_STATUS';

export const TEACHERS_REMOVE = 'TEACHERS_REMOVE';
export const TEACHERS_REMOVE_SUCCESS = 'TEACHERS_REMOVE_SUCCESS';
export const TEACHERS_REMOVE_FAIL = 'TEACHERS_REMOVE_FAIL';