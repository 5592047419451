import { takeEvery } from 'redux-saga/effects';
import { PAGES_FETCH, PAGES_UPLOAD, PAGES_EDIT } from '../../actions/pages';
import fetch from './fetch';
import upload from './upload';
import edit from './edit';

export default function* saga () {
  yield takeEvery(PAGES_FETCH, fetch);
  yield takeEvery(PAGES_UPLOAD, upload);
  yield takeEvery(PAGES_EDIT, edit);
};