import { call, put } from 'redux-saga/effects';
import { pagesFetchFail } from '../../AC/pages';
import PageApi from '../../../api/Pages';

function* upload({ data }){
  try {
    const response = yield call(PageApi.upload, data);

    if (response.status === 200) {
      const response = yield call(PageApi.updataFileData, {path: data.path, name:data.name});
      console.log( response.data ) 
    }

    if (response.status === 500) {
      console.log( response.data )
    }
  } catch(e) {
    console.error(e);
    yield put(pagesFetchFail(e.message));
  }
}

export default upload;