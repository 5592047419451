import { takeEvery } from 'redux-saga/effects';
import { FOLDERS_FETCH, FOLDERS_UPDATA, FOLDERS_DELETE } from '../../actions/folders';
import fetch from './fetch';
import updata from './updata';
import deleteFile from './delete';

export default function* saga () {
  yield takeEvery(FOLDERS_FETCH, fetch);
  yield takeEvery(FOLDERS_UPDATA, updata);
  yield takeEvery(FOLDERS_DELETE, deleteFile);
};