import { combineReducers } from 'redux';
import auth from './auth';
import reviews from './reviews';
import courses from './courses';
import users from './users';
import teachers from './teachers';
import pages from './pages';
import folders from './folders';

export default combineReducers({
  auth,
  reviews,
  courses,
  users,
  teachers,
  pages,
  folders,
});
