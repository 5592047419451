import * as actions from '../actions/teachers';

const initialState = {
  list: null,
  error: {},
  status: null,
};

function teachersReducer(state = initialState, action) {
  // console.log(action, state);
  switch(action.type) {
    case actions.TEACHERS_FETCH_SUCCESS:
      return { ...state, list: action.data };

    case actions.TEACHERS_FETCH_FAIL:
      return { ...state, error: { ...state.error, list: action.error } };

    case actions.TEACHERS_ADD_SUCCESS:
      return { ...state, list: [...state.list, action.data], status: 'successAdd' };

    case actions.TEACHERS_ADD_FAIL:
      return { ...state, status: 'failAdd' };

    case actions.TEACHERS_ADD_STATUS:
      return { ...state, status: action.status };

    case actions.TEACHERS_EDIT:
      return { ...state };

    case actions.TEACHERS_EDIT_SUCCESS:
      return { ...state, list: state.list.map(item => item.id !== action.data.id ? item : action.data), status: "successEdit" };

    case actions.TEACHERS_EDIT_FAIL:
      return { ...state, error: { ...state.error, [action.id]: action.error }, status: 'failEdit'  };

    case actions.TEACHERS_REMOVE_SUCCESS:
      return { ...state, list: state.list.filter(item => item.id !== action.id) };


    default:
      return state;
  }
}

export default teachersReducer;
