import React, { useState, useEffect } from 'react';
import { Root,InputList, InputItem, Input, Label, Button, ButtonContainer, CloseButton } from './styles';
import SelectMultiple from './../../../components/SelectMultipleFromTeachers'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { coursesFetch, } from './../../../store/AC/courses';
import { Modal } from '../../../components';
import { teachersEdit, teachersAdd, teachersAddStatus, teachersRemove } from './../../../store/AC/teachers';

function TeacherEdit( { content, courses, coursesFetch, statusTeachers, teachersEdit, teachersAdd, teachersAddStatus, closeModal } ){
  useEffect(() => {
    if( !courses?.list?.length ){
      coursesFetch();
    }
  }, [coursesFetch, courses]);

  if(content){
    delete content._id
  }
  const [state, setState] = useState( content || {
    "surname": "", // Багирова
    "name": "", //Александра
    "middlename": "", //Валерьевна
    "education": "", //высшее
    "academic": "", //кандидат технических наук
    "position": "", //младший научный сотрудник лаборатории наукометрии ГПНТБ СО РАН
    "qualification": "", //учитель начальных классов; социальный педагог
    "experience": "", //15 лет / 4 года
    "directionTraining": [""], //«Педагогика и методика начального образования»; «Социальная педагогика», Новосибирский государственный педагогический университет"
    "retraining": [""], //Использование дистанционных образовательных технологий и электронного обучения в образовательном процессе, 16 часов, 2020 г. (ГПНТБ СО РАН);
    "categories": [], //training
    "course": [], 
      // {
      //   "title": "Авторские профили и идентификаторы в наукометрических системах",
      //   "href": 15
      // }
    // "id": 1,
    "img": "",
    
  });

  const setValue = ( itemName , { target }, index ) => {
    console.log( target.value)
    if(index !== undefined){
      const itemInState = state[itemName]
      itemInState[index] = target.value
      setState({
        ...state,
        [itemName] : itemInState, 
      })
    }else{
      setState({
        ...state,
        [itemName] : target.value, 
      })
    }
  }

  const addNewItem = itemName => { 
    const itemFromState = state[itemName];
    itemFromState.push('') 
    setState({
      ...state,
      [itemName]: [...itemFromState],
    });
  }
  const delNewItem = itemName => { 
    const itemFromState = state[itemName];
    itemFromState.pop() 
    setState({
      ...state,
      [itemName]: [...itemFromState],
    });
  }
  const handleSelectMultiple = (itemName, val) => {
    setState({
      ...state,
      [itemName]: [...val],
    });
  }
  
  const categories = {
    retraining: 'Профессиональная переподготовка',
    training: 'Повышение квалификации',
  }


  let coursesData = {}
  if(courses.list){
    courses.list.forEach( el => {
      coursesData[el.id] = el.title
    })
  }
  let selectedCouses = state.course.map( el => ''+ el.href )
  const handleSelectMultipleCourses = val => {
    console.log(val);
    selectedCouses = [...val]
  }

  const save = () => {
    let data = {...state}
    delete data.show
    let saveCourse = []
    selectedCouses.forEach( el => {
      if(coursesData[el]){
        saveCourse.push( {
          title: coursesData[el],
          href: +el
        })
      }
    })
    data.course = saveCourse
    console.log(data.course);
    if(content){
      teachersEdit(data)
    }else{
      teachersAdd(data)
    }
    closeModal()
  }

  const setStatusTeachers = _=>{
    document.body.style.overflowY = 'auto';
    teachersAddStatus(null)
  }

  return (
    <Root>
      { content &&
        <ButtonContainer>
          <CloseButton onClick={ closeModal } >&#215;</CloseButton>
        </ButtonContainer>
      }
      <InputList>
        <InputItem>
          <Label>Фамилия</Label>
          <Input value={state.surname} onChange={ e => setValue('surname', e ) } />
        </InputItem>
        <InputItem>
          <Label>Имя</Label>
          <Input value={state.name}  onChange={ e => setValue('name', e ) }  />
        </InputItem>
        <InputItem>
          <Label>Отчество</Label>
          <Input value={state.middlename}  onChange={ e => setValue('middlename', e ) }  />
        </InputItem>
        <InputItem>
          <Label>Изображение</Label>
          <Input value={state.img}  onChange={ e => setValue('img', e ) }  />
        </InputItem>
        <InputItem>
          <Label>Образование</Label>
          <Input value={state.education}  onChange={ e => setValue('education', e ) }  />
        </InputItem>
        <InputItem>
          <Label>Учённая степень</Label>
          <Input value={state.academic}  onChange={ e => setValue('academic', e ) }  />
        </InputItem>
        <InputItem>
          <Label>Должность</Label>
          <Input value={state.position}  onChange={ e => setValue('position', e ) }  />
        </InputItem>
        <InputItem>
          <Label>Квалификация</Label>
          <Input value={state.qualification}  onChange={ e => setValue('qualification', e ) }  />
        </InputItem>
        <InputItem>
          <Label>Общий стаж работы / по специальности</Label>
          <Input value={state.experience}  onChange={ e => setValue('experience', e ) }  />
        </InputItem>
        <InputItem>
          <Label>Наименование направления подготовки и (или) специальности</Label>
          {state.directionTraining.map( (el, i) => 
            <Input value={el} key={'key_trainning_ind'+ i } onChange={ e => setValue('directionTraining', e, i ) }  />
          )}
          <div>
            <Button onClick={ ()=>{ addNewItem('directionTraining') }  }>Добавить</Button>
            <Button onClick={ ()=>{ delNewItem('directionTraining') }  }>Удалить</Button>
          </div>
        </InputItem>
        <InputItem>
          <Label>Повышение квалификации и профессиональная переподготовка</Label>
          {state.retraining.map( (el, i) => 
              <Input value={el}  key={'key_cvalification_'+ i } onChange={ e => setValue('retraining', e, i ) }  />
          )}
          <div>
            <Button onClick={ ()=>{ addNewItem('retraining') }  }>Добавить</Button>
            <Button onClick={ ()=>{ delNewItem('retraining') }  }>Удалить</Button>
          </div>
        </InputItem>
        <InputItem>
          <Label>Категория</Label>
          <SelectMultiple
            placeholder={'Выберите категорию'}
            onChange={(val)=>{ handleSelectMultiple('categories', val) } }
            data={categories}
            selectedItem={state.categories}
          />
        </InputItem>
        <InputItem>
          <Label>Список преподаваемых курсов</Label>
          <SelectMultiple
            placeholder={'Выберите курсы'}
            onChange={ handleSelectMultipleCourses }
            data={coursesData}
            selectedItem={selectedCouses}
            
          />
        </InputItem>
      </InputList>
      <ButtonContainer>
        <Button onClick={ save }>Сохраннить</Button>
        <Button onClick={ closeModal }>Отмена</Button>
      </ButtonContainer>
      { statusTeachers === 'successEdit' &&
        <Modal close={setStatusTeachers} >
          <p>Изменения успешно сохранены</p>
          <ButtonContainer>
            <Button onClick={setStatusTeachers}>Принять</Button>
          </ButtonContainer>
        </Modal>
      }
      { statusTeachers === 'failEdit' &&
        <Modal close={setStatusTeachers} >
          <p>Не удалось применить изменения</p>
          <ButtonContainer>
            <Button onClick={setStatusTeachers}>Принять</Button>
          </ButtonContainer>
        </Modal>
      }
    </Root>
  )
}
const mapStateToProps = ({ courses, teachers }) => ({
  courses,
  statusTeachers: teachers.status
});
const mapDispatchToProps = dispatch => bindActionCreators({
  coursesFetch,
  teachersEdit,
  teachersAdd, 
  teachersAddStatus,
  teachersRemove,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TeacherEdit)