import styled from 'styled-components';
import {
  BLACK_COLOR,
  WHITE_COLOR,
  PRIMARY_COLOR,
} from '../../../constants/styles';

export const Root = styled.div`
  padding: 20px;
  border: 1px solid ${BLACK_COLOR};
  background-color: ${WHITE_COLOR};
  display:flex;
  flex-direction: column;
`
export const Header = styled.div`
  display:flex;
  justify-content: space-between;
  align-items: center;
`
export const HeaderForm = styled.div`
  display:flex;
  flex-direction: row-reverse;
`
export const Form = styled.div`
  display:flex;
  flex-direction: column;
`
export const FormItem = styled.div`
  margin-bottom: 20px;
  &:last-child{
    margin-bottom: 0px;
  }
`

export const Title = styled.div`
  font-size: 18px;
  font-weight: 700;
`
export const Button = styled.button`
  padding: 12px 15px;
  font-family: 'Gotham';
  font-size: 14px;
  font-weight: bold;
  color: #252A34;
  background-color: #fff;
  border: 1px solid #FF165D;
  border-radius: 15px;
  cursor: pointer;
  transition: all .2s;

  &:hover{
    color: #fff;
    background-color: #FF165D;
  }
`
export const LinkListInputContainer = styled.div`
  border: 1px solid ${PRIMARY_COLOR};
  border-radius: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`
export const LinkListTitle = styled.div`
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
`
export const LinkListInput = styled.input`
  padding: 5px 15px;
  border: none;
  border-bottom: 1px solid ${PRIMARY_COLOR};
  outline: none;
  &:last-child{
    border-bottom: none;
  }
`
export const FormItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
`
export const BtnDeleteFormItem = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border:1px solid ${PRIMARY_COLOR};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  color: white;
  background: ${PRIMARY_COLOR};
  cursor:pointer;
  transition: all .3s;
  &:hover{ 
    background:white;
    color: black;
  }
`
export const BtnContainer = styled.div`
  display: flex;
  gap: 10px;
`
