import styled from 'styled-components';
import {
  // TABLET,
  // LAPTOP,
  // DESCTOP,
  // FS_MD,
  // FS_LGS,
  // FS_LG,
  // BLACK_COLOR,
  // GRAY_COLOR,
  PRIMARY_COLOR
} from '../../../constants/styles';

export const Root = styled.div`
  padding:20px;
  border: 1px solid black;
  background: white;

`

export const Button = styled.button`
  padding: 12px 15px;
  font-family: 'Gotham';
  font-size: 14px;
  font-weight: bold;
  color: #252A34;
  background-color: #fff;
  border: 1px solid #FF165D;
  border-radius: 15px;
  cursor: pointer;
  transition: all .2s;

  &:hover{
    color: #fff;
    background-color: #FF165D;
  }
`
export const BtnContainer = styled.div`
  display: flex;
  gap: 10px;
`
export const Title = styled.div`
  font-size: 16px;
  font-weight: 700;
`
export const Header = styled.div`
  display:flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: center;
`
export const InputList = styled.div`
  display: flex,
  flex-direction: column;
  margin: 20px 0 20px;
`

export const BtnDeleteFormItem = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border:1px solid ${PRIMARY_COLOR};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  color: white;
  background: ${PRIMARY_COLOR};
  cursor:pointer;
  transition: all .3s;
  &:hover{ 
    background:white;
    color: black;
  }
`