import { call, put } from 'redux-saga/effects';
import { folderSuccess, folderFail } from '../../AC/folders';
import FoldersApi from '../../../api/Folders';

function* folderFetch(res){
  try {
    const response = yield call(FoldersApi.getFolder, res.data);

    if (response.status === 200) {
      yield put(folderSuccess(response.data));
    }

    if (response.status === 500) {
      yield put(folderFail(response.data));
    }
  } catch(e) {
    console.error(e);
    yield put(folderFail(e.message));
  }
}

export default folderFetch;

