import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { folderFetch, folderUpdata, folderDelete } from '../../store/AC/folders';
import {
  Root, Folder, FolderIcon, FolderText, Image,
  FileList, Header, BackBtn, Url, Footer, InputFile,
  LabeInput, InputContainer, UploadBtn, ImageIcon ,
  ImageText, InputFileText, DeleteBtn, GetUrlBtn,
} from './styles';


function Folders({folders,  folderFetch, folderUpdata, folderDelete }) {
  const {folder, files} = folders.foldData || {folder:[], files:[]}


  const [path, setPath] = useState('/')

  useEffect(()=>{
    folderFetch(path)
  },[folderFetch, path])
  
  const changesFolder = name =>{
    if(path === '/' ){
      setPath(path + name)
    }else{
      setPath(path + `/${name}`)
    }
  }
  const backFolder = ()=>{
    let arrRoute = path.split('/')
    arrRoute.pop()
    if(arrRoute.length === 1){
      arrRoute.push('')
    }
    setPath( arrRoute.join('/') )
  } 

  const [fileName, setFileName] = useState('Загрузить файл...')
  const [file, setFile] = useState(undefined)

  const changeFile = ({target}) => {
    setFileName( target.files[0].name )
    setFile( target.files[0] )
  }
  const uploadImage = () => {
    
    if(file){
      let formData = new FormData()
      formData.append("name", fileName);
      formData.append("file", file);
      formData.append("path", path );
      
      if( files.map( el => el.name ).includes( fileName ) ){
        let res = window.confirm('Файл с таким именем уже существует. Заменить?')
        if(res){
          folderUpdata( {file:formData, fileName ,changes:folders.foldData, recycle: true} )
        }
      }else{
        folderUpdata( {file:formData, fileName ,changes:folders.foldData} )
      }

      formData = undefined
      setFile(undefined)
      setFileName('Загрузить файл...')
    }
  }
  const sortText = (a,b) =>{
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  }

  const deleteFile = (el) =>{
    let res = window.confirm('Удалить файл?')
    if(res){
      folderDelete({changes:folders.foldData, fileName:el.name})
    }
  }
  const getUrl = (el) =>{
    let path = folders.foldData.path
    if(path === '/') path+=el.name;
    else path+= '/'+el.name;
    alert( '/upload'+path )
  }


  return (
    <Root>
      <Header>
        <BackBtn onClick={backFolder}>{'<'}</BackBtn>
        <Url>{path}</Url>
      </Header>
      <FileList>
        {folder.map( el => (
          <Folder onClick={ ()=> changesFolder(el) } key={'folder' + el}>
            <FolderIcon></FolderIcon>
            <FolderText>{el}</FolderText>
          </Folder>))
        }
        {files.sort( sortText ).map( el => (
          <Image key={ 'image' + el.name }>
            <DeleteBtn onClick={ ()=> deleteFile(el) }>&times;</DeleteBtn>
            <GetUrlBtn onClick={ ()=> getUrl(el)} >?</GetUrlBtn>
            <ImageIcon
              style={ {backgroundImage: el.href.slice(-3) === 'pdf' ? "url(/images/pdf_icon.png)": `url("https://onpo.gpntbsib.ru${el.href}")`} }
            />
            <ImageText>
              {el.name}
            </ImageText>
          </Image>))
        }
      </FileList>
      <Footer>
        <InputContainer>
          <InputFile type={'file'} id='folder_input_file' onChange={e => changeFile(e)}/>
          <InputFileText>{fileName}</InputFileText>
          <LabeInput htmlFor='folder_input_file'>Выбрать файл</LabeInput>
        </InputContainer>
        <UploadBtn onClick={uploadImage} className={file?'active':''}>Загрузить</UploadBtn>
      </Footer>
    </Root>
  );
}

const mapStateToProps = ({ folders }) => ({
  folders
});

const mapDispatchToProps = dispatch => bindActionCreators({
  folderFetch,
  folderUpdata,
  folderDelete,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Folders);
