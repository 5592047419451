
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { pagesFetch, pagesEdit} from '../../store/AC/pages';
import { Root, Text, Error, List, Button, BtnContainer, Container } from './styles';
import Item from './Item';

function Graduate({ pagesFetch, pagesEdit, pages }) {
  const {page, status, error} = pages
  useEffect(() => {
    pagesFetch({name:'graduate'});
  }, [pagesFetch]);

  const [arrMap, arrMapSet] = useState( [ ] )

  useEffect( () => {
    let pageWork = []
    let index = 0
    for(let item of page?.navMenu || []){
      if(item.href[0] === '#'){
        pageWork.push({
          ...item,
          type:'paper',
        })
      }else{
        pageWork.push({
          ...item,
          type:'link',
        })
      }
      index++
    }
    for(let i = 0; i < page?.papers?.length || 0; i++){
      pageWork[i] = {...pageWork[i], ...page.papers[i] }
    }
    arrMapSet([...pageWork])
  },[page?.navMenu, page?.papers])
  
  const deleteItem = ind => {
    let res = window.confirm(`Удалить раздел "${arrMap[ind].title}". Данное действие нельзя отменить.`)
    if(res){
      arrMap.splice(ind, 1)
      arrMapSet( [...arrMap] )
    }
  }

  const savePage = () => {
    let res = {
      name: "graduate",
      navMenu:[],
      papers:[]
    }
    let arrLink = []
    let arrPaper = []
    for(let el of arrMap){
      if(el.type === 'paper'){
        arrPaper.push({
          href:el.href,
          paper:el.paper,
          title:el.title,
        })
      }
      if(el.type === 'link'){
        arrLink.push({
          href:el.href,
          title:el.title,
        })
      }
    }
    res.papers = arrPaper
    for(let el of arrPaper){
      res.navMenu.push({
        title: el.title,
        href: '#' + el.href
      })
    }
    for(let el of arrLink){
      res.navMenu.push({
        title: el.title,
        href: el.href
      })
    }
    res.navMenu[0] = {
      ... res.navMenu[0],
      active: true,
      red: true
    }
    pagesEdit(res)
  }

  const upItem = el => {
    let index = arrMap.indexOf(el)
    if(index > 0){
      let item = arrMap.splice(index, 1)[0]
      arrMap.splice(index-1,0, item)
      arrMapSet( [...arrMap] )
    }
  }
  const downItem = el => {
    let index = arrMap.indexOf(el)
    if( index >= 0 ?? index !== (arrMap.length - 1) ){
      let item = arrMap.splice(index, 1)[0]
      arrMap.splice(index+1,0, item)
      arrMapSet( [...arrMap] )
    }
  }

  const addLink = () => {
    arrMap.push({
      type:'link',
      title:'',
      href:'',
    })
    arrMapSet( [...arrMap] )
    console.log(arrMap);
  }
  const addPaper = () => {
    arrMap.push({
      type:'paper',
      title:'',
      href:'',
      paper:[],
    })
    arrMapSet( [...arrMap] )
  }
  return (
    <Root>
      {
        status === null &&
        <Text>Загрузка...</Text>
      }
      {
        status === 'error' &&
        <Error>Ошибка загрузки</Error>
      }
      {
        page && status==='saccess' && 
        <Fragment>
          <Container>
            <List>
              { (arrMap).map((el, ind) => 
                <Item data={el} key={el.title + ind}
                  deleteItem={ () => deleteItem(ind) }
                  savePage = {savePage}
                  upItem = {upItem}
                  downItem = {downItem}
                  />
              )}
            </List>
            <BtnContainer>
              <Button onClick={addLink}>Добавить ссылку</Button>
              <Button onClick={addPaper} >Добавить блок</Button>
              <Button onClick={savePage}>Сохранить</Button>
            </BtnContainer>
          </Container>
        </Fragment>
      }
    </Root>
  );
}


const mapStateToProps = ({ pages }) => ({
  pages,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  pagesFetch,
  pagesEdit,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Graduate)