import styled from 'styled-components';
import { FS_MD, PRIMARY_COLOR, BR_SM, WHITE_COLOR } from '../../constants/styles';

export const Root = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
`;

export const Text = styled.p`
  font-size: ${FS_MD};
  margin-bottom: 10px;
`;

export const Input = styled.input`
  padding: 10px;
  border: 2px solid ${PRIMARY_COLOR};
  border-radius: ${BR_SM};
  margin-bottom: 10px;
`;

export const Button = styled.button`
  padding: 10px;
  border: 2px solid ${PRIMARY_COLOR};
  background-color: ${PRIMARY_COLOR};
  color: ${WHITE_COLOR};
  font-weight: 700;
  border-radius: ${BR_SM};
  cursor:pointer;
`;
