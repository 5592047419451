export const COURSES_FETCH = 'COURSES_FETCH';
export const COURSES_FETCH_SUCCESS = 'COURSES_FETCH_SUCCESS';
export const COURSES_FETCH_FAIL = 'COURSES_FETCH_FAIL';

export const COURSE_FETCH = 'COURSE_FETCH';
export const COURSE_FETCH_SUCCESS = 'COURSE_FETCH_SUCCESS';
export const COURSE_FETCH_FAIL = 'COURSE_FETCH_FAIL';

export const COURSE_ADD = 'COURSE_ADD';
export const COURSE_ADD_SUCCESS = 'COURSE_ADD_SUCCESS';
export const COURSE_ADD_FAIL = 'COURSE_ADD_FAIL';
export const COURSE_ADD_STATUS = 'COURSE_ADD_STATUS';

export const COURSE_EDIT = 'COURSE_EDIT';
export const COURSE_EDIT_SUCCESS = 'COURSE_EDIT_SUCCESS';
export const COURSE_EDIT_FAIL = 'COURSE_EDIT_FAIL';

export const COURSE_REMOVE = 'COURSE_REMOVE';
export const COURSE_REMOVE_SUCCESS = 'COURSE_REMOVE_SUCCESS';
export const COURSE_REMOVE_FAIL = 'COURSE_REMOVE_FAIL';
