import * as actions from '../actions/pages';

const initialState = {
  page: null,
  error: {},
  status: null,
};

function pagesReducer(state = initialState, action) {
  switch(action.type) {
    case actions.PAGES_FETCH:
      return { ...state, page: action.data };
    case actions.PAGES_FETCH_SUCCESS:
      return { ...state, page: action.data, status:'saccess' };
    case actions.PAGES_FETCH_FAIL:
      return { ...state, error: action.data, status:'error'};

    case actions.PAGES_EDIT:
      return { ...state, page:null, status:null };
    case actions.PAGES_EDIT_SUCCESS:
      return { ...state, page: action.data, status:'saccess' };
    case actions.PAGES_EDIT_FAIL:
      return { ...state, error: action.data, status:'error' };


    default:
      return state;
  }
}

export default pagesReducer;