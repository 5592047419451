import * as actions from '../actions/folders';

const initialState = {
  foldData: null,
  error: {},
  status: null,
};

function foldersReducer(state = initialState, action) {
  switch(action.type) {
    case actions.FOLDERS_FETCH:
      return { ...state };
    case actions.FOLDERS_SUCCESS:
      return { ...state, foldData: action.data, status:'saccess' };
    case actions.FOLDERS_FAIL:
      return { ...state, error: action.data, status:'error'};
    case actions.FOLDERS_UPDATA:
      return { ...state, status:'upload'};

    default:
      return state;
  }
}

export default foldersReducer;