import styled from 'styled-components';
import {
  // TABLET,
  // LAPTOP,
  // DESCTOP,
  // FS_MD,
  // FS_LGS,
  // FS_LG,
  BLACK_COLOR,
  WHITE_COLOR,
  PRIMARY_COLOR,
} from '../../../../constants/styles';

export const Root = styled.div`
  display:flex;
  flex-direction: column;
`
export const InputEdit = styled.input`
  padding: 5px 15px;
  border: 1px solid ${PRIMARY_COLOR};
  border-radius: 5px;
`
export const InputLabel = styled.label`
  margin-bottom: 5px;
  font-weight: 700;
`
