import { call, put } from 'redux-saga/effects';
import { folderSuccess, folderFail } from '../../AC/folders';
import FoldersApi from '../../../api/Folders';

function* updata( {data} ){
  const {changes, file, fileName, recycle} = data
  try {
    const response = yield call(FoldersApi.upload, file);

    if (response.status === 200 && !recycle) {
      changes.files.push({
        name: fileName,
        href: '/upload' + ( changes.path === '/' ? "/": changes.path + '/') + fileName
      })
      
      const response = yield call(FoldersApi.updata, changes);
      if (response.status === 200) {
        yield put(folderSuccess(changes));
      }
      if (response.status === 500) {
        console.log( response.data )
      }
    }

    if (response.status === 500) {
      console.log( response.data )
    }
  } catch(e) {
    console.error(e);
    yield put(folderFail(e.message));
  }
}

export default updata;