import * as actions from '../actions/pages';

// GET PAGE
export const pagesFetch = data => ({ type: actions.PAGES_FETCH, data });
export const pagesFetchSuccess = data => ({ type: actions.PAGES_FETCH_SUCCESS, data,});
export const pagesFetchFail = error => ({ type: actions.PAGES_FETCH_FAIL, error});

// EDIT PAGE
export const pagesEdit = data => ({ type: actions.PAGES_EDIT, data });
export const pagesEditSuccess = data => ({ type: actions.PAGES_EDIT_SUCCESS, data });
export const pagesEditFail = error => ({ type: actions.PAGES_EDIT_FAIL, error });

// UPLOAD
export const pagesUpload = data => ({ type: actions.PAGES_UPLOAD, data });