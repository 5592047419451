import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import { Header, PrivateRoute } from '../../components';
import Loading from '../Loading';
import Admin from '../Admin';

function App({ isAuth, loading }) {

  return (
    <Fragment>
      <Header />
      { loading && <Loading /> }
      {
        loading === false &&
          <Switch>
            <PrivateRoute path="/" component={Admin} auth={isAuth} />
            {/* <PrivateRoute path="/admin" component={Admin} auth={isAuth} /> */}
          </Switch>
      }
    </Fragment>
  );
}

App.propTypes = {
  isAuth:    PropTypes.bool.isRequired,
  loading:   PropTypes.bool,
};

const mapStateToProps = ({ auth }) => ({
  isAuth: auth.isAuth,
  loading: auth.loading,
});



export default connect(mapStateToProps)(App);
