import { call, put } from 'redux-saga/effects';
import { pagesFetchSuccess, pagesFetchFail } from '../../AC/pages';
import PageApi from '../../../api/Pages';

function* pageFetch({ data }){
  try {
    const response = yield call(PageApi.getPage, data.name);

    if (response.status === 200) {
      yield put(pagesFetchSuccess(response.data));
    }

    if (response.status === 500) {
      yield put(pagesFetchFail(response.data));
    }
  } catch(e) {
    console.error(e);
    yield put(pagesFetchFail(e.message));
  }
}

export default pageFetch;