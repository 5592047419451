import { call, put } from 'redux-saga/effects';
import AuthApi from '../../../api/Auth';
import { authSuccess, authFail } from '../../AC/auth';

function getCookie(name) {
  let matches = document.cookie.match(new RegExp(
    "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

function setCookie(name, value, options = {}) {

  options = {
    path: '/',
    // при необходимости добавьте другие значения по умолчанию
    ...options
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

  for (let optionKey in options) {
    updatedCookie += "; " + optionKey;
    let optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += "=" + optionValue;
    }
  }

  document.cookie = updatedCookie;
}

function deleteCookie(name) {
  setCookie(name, "", {
    'max-age': -1
  })
}



function* fetchLots( { password } ) {
  try {

    let pass = getCookie('pass')
    let response = {}

    response = yield call( AuthApi.check, password || pass );
    
    
    if (response.status === 200) {
      if(password){
        setCookie('pass', password)
      }
      yield put(authSuccess());
    }

    else if (response.status === 401) {
      deleteCookie('pass')
      yield put(authFail());
    }
  } catch(e) {
    deleteCookie('pass')
    yield put(authFail());
    console.error(e);
  }
}

export default fetchLots;