import axios from 'axios';

class FoldersApi {

  static getFolder(path) {
    return axios
      .get(`/api/fileData?path=${path}`)
      .then(response => response)
      .catch(({ response }) => ({ ...response }));
  }

  static updata(changes) {
    return axios
      .put(`/api/fileData`, {changes})
      .then(response => response)
      .catch(({ response }) => ({ ...response }));
  }

  static upload(data) {
    return axios
      .post(`https://onpo.gpntbsib.ru/api/upload`, data)
      .then(response => response)
      .catch(({ response }) => ({ ...response }));
  }

  static deleteFile(data) {
    return axios
      .delete(`https://onpo.gpntbsib.ru/api/delete`, {data})
      .then(response => response)
      .catch(({ response }) => ({ ...response }));
  }

}

export default FoldersApi;