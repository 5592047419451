import { takeEvery } from 'redux-saga/effects';
import { TEACHERS_FETCH, TEACHERS_ADD, TEACHERS_EDIT, TEACHERS_REMOVE } from '../../actions/teachers';
import fetch from './fetch';
import edit from './edit';
import create from './create';
import remove from './remove';

export default function* saga () {
  yield takeEvery(TEACHERS_FETCH, fetch);
  yield takeEvery(TEACHERS_EDIT, edit);
  yield takeEvery(TEACHERS_ADD, create);
  yield takeEvery(TEACHERS_REMOVE, remove);
};