import styled from 'styled-components';
import { FS_MD, PRIMARY_COLOR, GRAY_COLOR, WHITE_COLOR, FS_SM } from '../../constants/styles';

export const Root = styled.div`
  position: relative;
  width: 100%;
  border: 1px solid ${PRIMARY_COLOR};
  border-radius: 5px;
  cursor: pointer;
`;

export const Placeholder = styled.div`
  width: 100%;
  padding-right: 20px;
  position: relative;

  &>div{
    padding: 10px;
    font-size: ${FS_MD};
  }
`

export const Clear = styled.div`
  width: 25px;
  height: 25px;
  background: ${PRIMARY_COLOR};
  color: ${WHITE_COLOR};
  font-size: 16px;
  line-height: 16px;
  position: absolute;
  right: 5px;
  top: 8px;
  border-radius: 5px;
  background-image: url('/static/media/remove.55fda794.svg');
  background-size: 16px 16px;
  background-position: center;
  background-repeat: no-repeat;
`

export const List = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  display: none;
  background: ${WHITE_COLOR};
  border: 1px solid ${GRAY_COLOR};
  z-index: 2;
  &.active{
    display: block;
  }
`
export const Item = styled.div`
  width: 100%;
  padding: 5px 10px;
  font-size: ${FS_MD};
  border-bottom: 1px solid ${GRAY_COLOR};
  &.active{
    background: ${PRIMARY_COLOR};
    color: ${WHITE_COLOR};
  }
`