import React from 'react';
import { Item, HeadItem, Root, List, Button } from './styles';
import { Link } from 'react-router-dom';

function NavBar() {
  return (
    <Root>
      <List>
        <HeadItem>
            Меню
        </HeadItem>
        <Item>
          <Link to='/courses'>
            <Button>
              Курсы
            </Button>
          </Link>
        </Item>
        <Item>
          <Link to='/teachers'>
            <Button>
              Преподаватели
            </Button>
          </Link>
        </Item>
        <Item>
          <Link to='/slider'>
            <Button>
              Карусель
            </Button>
          </Link>
        </Item>
        <Item>
          <Link to='/reviews'>
            <Button>
              Отзывы
            </Button>
          </Link>
        </Item>
        <Item>
          <Link to='/graduate'>
            <Button>
              Аспирантура
            </Button>
          </Link>
        </Item>
        <Item>
          <Link to='/structure'>
            <Button>
              Сведенья об организации
            </Button>
          </Link>
        </Item>
        <Item>
          <Link to='/folders'>
            <Button>
              Файлы
            </Button>
          </Link>
        </Item>
      </List>
    </Root>
  );
}

export default NavBar;
