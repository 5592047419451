import styled from 'styled-components';
import { FS_LGM, BLACK_COLOR, PRIMARY_COLOR, WHITE_COLOR } from '../../constants/styles';

export const Root = styled.main`
  display: flex;
  flex-direction: column;
  height: 100%;;
`;

export const Text = styled.div`
  margin-top: 50px;
  align-self: center;
  font-size: ${FS_LGM};
  color: ${BLACK_COLOR};
`;

export const Error = styled.div`
  margin-top: 50px;
  align-self: center;
  font-size: ${FS_LGM};
  color: ${PRIMARY_COLOR};
`;

export const List = styled.div`
  margin: 20px auto;
  width: 1200px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const Button= styled.button`
  background: ${WHITE_COLOR};
  padding: 10px 20px;
  border-radius: 15px;
  font-size: 16px;
  line-height: 16px;
  border: 2px solid ${PRIMARY_COLOR};
  margin-right: 20px;
  font-weight: 500;
  cursor:pointer;
  transition: .3s all;
  margin-top: 10px;
  &:hover{
    background: ${PRIMARY_COLOR};
    color: ${WHITE_COLOR};
  }
`

export const Container = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
`
