import styled from 'styled-components';
import {
  // TABLET,
  // DESCTOP,
  PRIMARY_COLOR,
  // GRAY_COLOR,
  // TABLET,
  WHITE_COLOR,
  BLACK_COLOR
} from '../../../constants/styles';

export const Select = styled.select`
  width: 100%;
  padding: 10px;
  border: 1px solid ${PRIMARY_COLOR};
  border-radius: 5px;
  font-size: 16px;
  line-height: 16px;
`

export const Root = styled.div`
  padding: 20px;
  display: flex;
  width: 100%;
  background-color: ${WHITE_COLOR};
  flex-direction: column;
`

export const Input = styled.input`
  display: flex;
  width: 100%;
  padding: 10px;
  border: 1px solid ${PRIMARY_COLOR};
  border-radius: 5px;
  font-size: 16px;
  line-height: 16px;
`
export const Label = styled.label`
  width: 100%;
  margin-bottom: 10px;
  font-size: 15px;
  line-height: 16px;
  font-weight: 700;
  
`
export const InputList = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  margin-bottom: 40px;
`
export const InputItem = styled.li`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  &:last-child{
    margin-bottom: 0;
  }

`
export const Button= styled.button`
  background: ${WHITE_COLOR};
  padding: 10px 20px;
  border-radius: 15px;
  font-size: 16px;
  line-height: 16px;
  border: 2px solid ${PRIMARY_COLOR};
  margin-right: 20px;
  font-weight: 500;
  cursor:pointer;
  transition: .3s all;
  margin-top: 10px;
  &:hover{
    background: ${PRIMARY_COLOR};
    color: ${WHITE_COLOR};
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  &>button{
    margin-right: 20px;
    &:last-child{
      margin-right: 0;
    }
  }
`

export const CloseButton= styled.button`
  background: ${WHITE_COLOR};
  padding: 5px;
  border-radius: 5px;
  font-size: 23px;
  line-height: 18px;
  border: 2px solid ${PRIMARY_COLOR};
  margin-right: 20px;
  font-weight: 500;
  cursor:pointer;
  transition: .3s all;
  margin-top: 10px;
  &:hover{
    background: ${PRIMARY_COLOR};
    color: ${WHITE_COLOR};
  }
`