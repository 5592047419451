import React, { useState, useRef  } from 'react';
import PropTypes from 'prop-types';
import {
  Root, Placeholder, List, Item, Clear,
} from './styles';

function SelectMultiple({ placeholder, data, onChange, selectedItem }) {
  const [selected, setSelected] = useState( selectedItem || []);
  const [isOpen, setOpen] = useState(false);

  const wrapNode = useRef()

  const eventCloseSelect = e =>{
    console.log(wrapNode, e);
    if(wrapNode?.current?.contains( e.target) ) return;
    console.log('eventCloseSelect');
    setOpen(false)
    document.body.removeEventListener('click', eventCloseSelect)
  }

  function handleClick() {
    setOpen(!isOpen);
    if(!isOpen){
      document.body.addEventListener('click', eventCloseSelect)
    }else{
      document.body.removeEventListener('click', eventCloseSelect)
    }
  }
  

  function handleSelect(teacherId) {
    const isIncludes = selected.includes(teacherId);
    console.log(teacherId);
    if ( isIncludes ) {
      setSelected(selected.filter(item => item !== teacherId));
      onChange(selected.filter(item => item !== teacherId));
      return;
    }

    setSelected([...selected, teacherId]);
    onChange([...selected, teacherId]);
  }
  function clear(){
    setSelected([]);
    onChange([]);
  }

  return (
    <Root ref={wrapNode}>
      <Placeholder>
        <div  onClick={handleClick}>
          { selected.reduce( (acc, el )=> acc + data[el] + ', \n' , '' ) || placeholder }
        </div>
        <Clear onClick={clear}/>
      </Placeholder>
      {isOpen && 
        <List className={`${isOpen && 'active'}`} >
          {Object.keys( data ).map( key =>
            <Item key={'teacher_course' + key} onClick={()=>{handleSelect(key)}} className={`${selected.includes(key) && 'active'}`}>
              {data[key]}
            </Item>
          )}
        </List>
      }
    </Root>
  );
}

SelectMultiple.propTypes = {
  placeholder: PropTypes.string,
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  selectedItem: PropTypes.array,
};

SelectMultiple.defaultProps = {
  placeholder: '',
};

export default SelectMultiple;