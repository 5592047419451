import SunEditor, {buttonList} from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File

import React, { useEffect, useState } from 'react';
import { Root, Title, Button, Header, InputList, BtnContainer, BtnDeleteFormItem } from './styles';
import Input from './Input'

function ItemCarusel({data, deleteItem, downItem, upItem}){
  const [isOpen, setIsOpen] = useState(false)

  const setRichText = text => {
    data.data.text = text
  }
  return( 
    <Root>
      
      {!isOpen && 
        <Header>
          <BtnContainer>
            <BtnDeleteFormItem onClick={()=> upItem(data)}>&and;</BtnDeleteFormItem>
            <BtnDeleteFormItem onClick={()=> downItem(data)}>&or;</BtnDeleteFormItem>
            <Button onClick={ ()=> setIsOpen(true) }>Открыть</Button>
          </BtnContainer>
          <Title>{data.data.title}</Title>
        </Header> 
      }
      {isOpen && data.isHtml && <>
          <Header>
            <Button onClick={ ()=> setIsOpen(false) }>Закрыть</Button>
            <Button onClick={ ()=> deleteItem(data) }>Удалить</Button>
          </Header>
          <InputList>
            <Input data={data.data} keyName={'title'} title={'Загаловок'}/>
          </InputList>
          <SunEditor setOptions={{ 
              height: 200,
              buttonList: buttonList.complex
            }}
            lang="ru"
            defaultValue={data.data.text}
            onChange={text => setRichText(text) }
          />
        </>
      }
      {isOpen && data.isCourse && <>
          <Header>
            <Button onClick={ ()=> setIsOpen(false) }>Закрыть</Button>
            <Title>{data.data.title}</Title>
          </Header> 
          <div>
            Редактирование старых слайдов недоступно.
          </div>
          <div>
            <Button onClick={ ()=> deleteItem(data) }>Удалить</Button>
          </div>
        </>
      }
    </Root>
  )
}


export default ItemCarusel